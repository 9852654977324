export const adminLteConf = {
    sidebarLeftMenu: [
        {
            label: 'Menú de navegación ',
            separator: true
        },
        {
            label: ' Inicio', 
            route: '/',
            iconClasses: 'fa fa-home ', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ]
        },
        { 
            label: 'Citas', 
            route: '/citas', 
            iconClasses: 'fa fa-id-badge', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: 'Usuarios App', 
            route: '/usuariosA', 
            iconClasses: 'fa fa-mobile', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
                { 
            label: ' Usuarios', 
            route: '/usuarios', 
            iconClasses: 'fa fa-user-o', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
    ]
};