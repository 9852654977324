export const environment = {
  production: false,
  // url_api: 'http://localhost:3400/', //localhost o ip
    // url_api: 'https://mz.tlk.com.mx/',
    url_api: 'https://api.wallsadministacion.tlk.com.mx/',
  firebase : {
    apiKey: "",
    authDomain: "d10-22f55.firebaseapp.com",
    projectId: "d10-22f55",
    storageBucket: "d10-22f55.appspot.com",
    messagingSenderId: "166232100812",
    appId: "1:166232100812:web:3d307f6393b8702d4c9769",
    measurementId: "G-RES86JMTY3"
  }
};